// 
// profile.scss
//

.post-user-comment-box {
    background-color: $gray-100;
    margin: 0 -.75rem;
    padding: 1rem;
    margin-top: 20px;
}
.react-colorful__alpha, .react-colorful__hue {
    position: relative;
    height: 13px!important;
}
.react-colorful__pointer {
    position: absolute;
    z-index: 1;
    box-sizing: border-box;
    width: 15px!important;
    height: 15px!important;
    transform: translate(-50%,-50%);
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
}
.react-colorful {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 150px!important;
    height: 150px!important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}