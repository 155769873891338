//
// tasks.scss
//

.commentsNum {
  position: absolute;
  top: -3px;
  right: 5px;
  background-color: #ececec;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  text-align: center;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.board {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
}
.scrollTasks {
  max-height: 1200px;
  overflow-y: auto;
  position: relative;
}
.scrollTasks::-webkit-scrollbar {
  margin-left: 10px;
  width: 6px; /* Set the width of the scrollbar */
  position: absolute;
  right: -10px;
}

.scrollTasks::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Round corners of the thumb */
  margin-left: 10px;
}

.scrollTasks::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  margin-left: 10px; /* Color of the scrollbar track */
}
.tasks {
  display: inline-block;
  width: 22rem;
  padding: 0 1rem 1rem 1rem;
  vertical-align: top;
  margin-bottom: $grid-gutter-width;
  background-color: $card-bg;
  @include border-radius($border-radius-sm);

  &.tasks:not(:last-child) {
    margin-right: 1.25rem;
  }

  .card {
    white-space: normal;
    margin-top: 1rem;
  }

  .task-header {
    background-color: $card-cap-bg;
    padding: 1rem;
    margin: 0 -1rem;
    @include border-radius($border-radius-sm);
  }
}

.task-list-items {
  min-height: 100px;
  position: relative;

  .card {
    cursor: pointer;
  }

  &:before {
    content: "No Tasks";
    position: absolute;
    line-height: 110px;
    width: 100%;
    text-align: center;
    font-weight: 600;
  }
}

// Task add modal
.task-modal-content {
  .form-control-light {
    background-color: lighten($gray-200, 2.5%) !important;
    border-color: lighten($gray-200, 2.5%) !important;
  }
}

// task - gantt
.gantt-task-details {
  min-width: 220px;
}

// task search
.task-search {
  .search-input {
    padding-left: 32px;
    z-index: 10;
  }
  .icon-search {
    z-index: 11;
    position: absolute;
    left: 10px;
    top: 10px;
  }
}
.no-border {
  border: 0;
  box-shadow: none; /* You may want to include this as bootstrap applies these styles too */
}
// .limited-height-scroll {
//   max-height: 1230px; /* Set the maximum height you desire */
//   overflow-y: scroll;

//   /* Enable vertical scrollbar when the content exceeds the height */
// }
// .limited-height-scrollCol {
//   max-height: 1320; /* Set the maximum height you desire */
//   overflow-y: auto;

//   /* Enable vertical scrollbar when the content exceeds the height */
// }
